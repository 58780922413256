<template>
  <div class="form">
    <h1>User Configurations</h1>
    <template v-if="user">
      <div class="user-details">
        <h2>
          {{ `${fullName}`
          }}<span class="text-small"
            ><i>({{ user.id }})</i></span
          >
        </h2>
        <div class="user-info">
          <p>
            <a :href="`mailto:${user.email}`">{{ user.email }}</a>
          </p>
          <button class="slick-button" @click="reset">
            <span>Change User</span>
          </button>
        </div>
      </div>
      <div class="sections">
        <div class="section-header">
          <h4>Retake configuration</h4>
          <label for="activeRetake">
            <switches
              v-if="retakeConfig"
              id="activeRetake"
              color="teal"
              theme="es"
              v-model="retakeActive"
            ></switches>
          </label>
        </div>
        <template v-if="retakeConfig">
          <user-retake
            :disabled="isBusy || !retakeActive"
            v-model="retakeConfig"
            @error="error => (hasError = error)"
            :assessmentTypes="assessmentTypes"
            :defaultRetakeConfigurations="defaultRetakeConfigurations"
          />
          <div class="actions">
            <es-btn
              :disabled="isBusy || hasError"
              color="teal"
              :full="true"
              @click="saveChanges"
              >Save</es-btn
            >
          </div>
        </template>
        <template v-else>
          <div>
            <p>No retake config found.</p>

            <es-btn @click="startRetake" class="orange"
              >Start setting retake limit</es-btn
            >
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div>
        <form @submit.prevent="submit">
          <div>
            <div>
              <label for="userId"><span>User ID</span></label>
            </div>
            <ValidationObserver v-slot="{ invalid }">
              <div class="form-row">
                <ValidationProvider rules="required|uuid" v-slot="{ errors }">
                  <input
                    id="userId"
                    type="text"
                    v-model="userId"
                    :disabled="isBusy"
                    placeholder="Enter user ID"
                  />
                  <p v-if="errors.length > 0" class="error">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
              <div v-if="isBusy">
                <p>Fetching user configurations.</p>
              </div>
              <div>
                <es-btn
                  color="teal"
                  :disabled="invalid || isBusy"
                  :full="true"
                  type="submit"
                  >Submit</es-btn
                >
              </div>
            </ValidationObserver>
          </div>
        </form>
      </div>
    </template>
  </div>
</template>
<script>
import { UserRetake } from "./../../components/user-configuration";
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import Switches from "vue-switches";
export default {
  data: () => ({
    userId: "",
    hasError: false
  }),
  components: {
    UserRetake,
    Switches
  },
  computed: {
    ...mapGetters("userConfigurations", [
      "user",
      "assessmentTypes",
      "retakeConfigurations",
      "defaultRetakeConfigurations",
      "isRetakeActive",
      "isBusy"
    ]),
    retakeConfig: {
      get() {
        return this.retakeConfigurations;
      },
      set(value) {
        this.updateRetakeConfigurations(value);
      }
    },
    retakeActive: {
      get() {
        return this.isRetakeActive;
      },
      set(value) {
        this.updateRetakeActive(value);
      }
    },
    fullName() {
      return `${this.user?.first_name} ${this.user?.last_name}`.trim();
    }
  },
  methods: {
    ...mapActions("userConfigurations", [
      "fetchConfigurationsForUser",
      "updateRetakeConfigurations",
      "updateRetakeActive",
      "saveChanges",
      "resetUserConfiguration"
    ]),
    submit() {
      this.$router.replace({ query: { uid: this.userId } }).catch(() => {});
      this.fetchConfigurationsForUser({
        userId: this.userId
      });
    },
    reset() {
      this.userId = "";
      this.$router.replace({ query: {} }).catch(() => {});
      this.resetUserConfiguration();
    },
    startRetake() {
      this.retakeConfig = {};
    }
  },
  beforeMount() {
    const queryparams = this.$route.query;
    if (queryparams?.uid) {
      this.userId = queryparams.uid;
      this.fetchConfigurationsForUser({
        userId: this.userId
      });
    }
  },
  destroyed() {
    this.userId = "";
    this.resetUserConfiguration();
  }
};
</script>
<style lang="scss" scoped>
h1 {
  margin-bottom: 1.5em;
}
.form {
  width: 400px;
  margin: auto;
  .submit-errors {
    margin: 1.5em 0;
    & ::v-deep p {
      margin: 0;
      color: $cranberry;
      font-family: $headline;
    }
  }
  .submit-success {
    margin-top: -20px;
    color: $teal;

    & ::v-deep small {
      font-family: "British Council Sans", sans-serif;
      color: #1a1a1b;
      font-size: 12px;
      display: block;
      padding-left: 3px;
    }
  }
  .busy {
    text-align: center;
    margin: 0;
    font-family: $headline;
  }

  .form-row {
    margin-bottom: 1em;

    & label {
      display: block;
      font-size: 12px;
      cursor: pointer;

      & svg {
        color: $cranberry;
        width: 8px;
        vertical-align: top;
        margin-left: 0.25em;
      }
    }
    &.error {
      & input {
        border-color: $cranberry;
      }
    }
    & input {
      @include input;
    }
    & p.error {
      font-size: 14px;
      color: $cranberry;
      margin-top: 0.25em;
    }
    & button {
      width: 100%;
      margin-top: 2em;
    }
  }
}
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  h4 {
    margin: 0;
  }
  label {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    & span {
      margin-right: 0.5em;
    }
  }
}
.user-details {
  margin-bottom: 1em;
  h2 {
    margin: 0;
  }
  p {
    margin: 0;
  }
}
.slick-button {
  margin-left: 1em;
  & span {
    font-size: 12px;
  }
}
.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
  & p {
    margin: 0;
  }
  & a {
    color: $teal;
    text-decoration: none;
  }
  & button {
    background: #b71c1c;
    color: white;
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    & span {
      height: fit-content;
      display: block;
    }
  }
}
.actions {
  margin: 1em 0;
}
</style>
