var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isReady)?_c('div',[_c('ValidationObserver',{ref:"retakeValidator"},[_c('ul',{staticClass:"retakes",class:{ disabled: _vm.disabled }},_vm._l((_vm.retakeConfigurations),function(retakeConfiguration,$idx){return _c('li',{key:retakeConfiguration.key,staticClass:"retakes__item"},[_c('div',[_c('div',[_c('h4',[_vm._v(_vm._s(retakeConfiguration.label))])]),_vm._l((_vm.retakeConfigurations[$idx]
                .restrictions),function(restriction,$rIdx){return _c('div',{key:((retakeConfiguration.key) + "_" + $rIdx),staticClass:"restriction"},[_c('div',[_c('div',{staticClass:"form-row"},[_c('label',{attrs:{"for":("amount_" + (retakeConfiguration.key) + "_" + $rIdx)}},[_c('span',{staticClass:"label"},[_vm._v("Amount")])])]),_c('div',{staticClass:"form-row"},[_c('ValidationProvider',{attrs:{"rules":"required|min:0|max:9999999|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.retakeConfigurations[$idx].restrictions[$rIdx].amount
                      ),expression:"\n                        retakeConfigurations[$idx].restrictions[$rIdx].amount\n                      "}],key:("amount_" + (retakeConfiguration.key) + "_" + $rIdx),staticClass:"input",class:{
                        error: errors.length > 0,
                        disabled: _vm.disabled
                      },attrs:{"name":((retakeConfiguration.label) + " amount for " + (restriction.time_unit)),"disabled":_vm.disabled,"id":("amount_" + (retakeConfiguration.key) + "_" + $rIdx),"type":"number","min":"0"},domProps:{"value":(
                        _vm.retakeConfigurations[$idx].restrictions[$rIdx].amount
                      )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.retakeConfigurations[$idx].restrictions[$rIdx], "amount", $event.target.value)}}}),(errors.length > 0)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('div',[_c('div',{staticClass:"form-row"},[_c('label',{attrs:{"for":("duration_" + (retakeConfiguration.key) + "_" + $rIdx)}},[_c('span',{staticClass:"label"},[_vm._v("Duration in "),_c('strong',[_vm._v(_vm._s(restriction.time_unit))])])])]),_c('div',{staticClass:"form-row"},[_c('ValidationProvider',{attrs:{"rules":"required|min:0|max:9999999|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.retakeConfigurations[$idx].restrictions[$rIdx]
                          .duration
                      ),expression:"\n                        retakeConfigurations[$idx].restrictions[$rIdx]\n                          .duration\n                      "}],key:("duration_" + (retakeConfiguration.key) + "_" + $rIdx),class:{
                        error: errors.length > 0,
                        disabled: _vm.disabled
                      },attrs:{"name":((retakeConfiguration.label) + " duration for " + (restriction.time_unit)),"disabled":_vm.disabled,"id":("duration_" + (retakeConfiguration.key) + "_" + $rIdx),"type":"number","min":"0"},domProps:{"value":(
                        _vm.retakeConfigurations[$idx].restrictions[$rIdx]
                          .duration
                      )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.retakeConfigurations[$idx].restrictions[$rIdx]
                          , "duration", $event.target.value)}}}),(errors.length > 0)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)])])})],2)])}),0)])],1):_c('div',[_c('p',[_vm._v("Loading...")])])])}
var staticRenderFns = []

export { render, staticRenderFns }