








































































































import Vue, { PropType } from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

interface RetakeConfiguration {
  key: string;
  restrictions: Array<{
    amount: number;
    duration: number;
    time_unit: string;
  }>;
  label?: string;
}

export default Vue.extend<
  {
    retakeConfigurations: Array<RetakeConfiguration>;
    isReady: boolean;
  },
  {},
  {
    coreAssessmentTypes: Array<string>;
    spokenAssessmentTypes: Array<string>;
    writingAssessmentTypes: Array<string>;
    assessmentGroup: Record<string, Array<string>>;
    newRetakeConfigurations: Record<
      string,
      {
        restrictions: Array<{
          amount: number;
          duration: number;
          time_unit: string;
        }>;
      }
    >;
  },
  {
    value: Record<string, RetakeConfiguration>;
    assessmentTypes: Array<string>;
    defaultRetakeConfigurations: Record<
      string,
      {
        restrictions: Array<{
          amount: number;
          duration: number;
          time_unit: string;
        }>;
      }
    >;
    disabled: boolean;
  }
>({
  props: {
    value: {
      type: Object as PropType<Record<string, RetakeConfiguration>>
    },
    assessmentTypes: {
      type: Array as PropType<Array<string>>
    },
    defaultRetakeConfigurations: {
      type: Object as PropType<
        Record<
          string,
          {
            restrictions: Array<{
              amount: number;
              duration: number;
              time_unit: string;
            }>;
          }
        >
      >
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  data: () => ({
    isReady: false,
    retakeConfigurations: [
      {
        key: "core",
        label: "Core skill",
        restrictions: [
          {
            amount: 0,
            duration: 0,
            time_unit: "hours"
          },
          {
            amount: 0,
            duration: 0,
            time_unit: "days"
          }
        ]
      },
      {
        label: "Speaking skill",
        key: "spoken",
        restrictions: [
          {
            amount: 0,
            duration: 0,
            time_unit: "hours"
          },
          {
            amount: 0,
            duration: 0,
            time_unit: "days"
          }
        ]
      },
      {
        label: "Writing skill",
        key: "writing",
        restrictions: [
          {
            amount: 0,
            duration: 0,
            time_unit: "hours"
          },
          {
            amount: 0,
            duration: 0,
            time_unit: "days"
          }
        ]
      }
    ]
  }),
  computed: {
    coreAssessmentTypes() {
      return this.assessmentTypes.filter(
        type => type.startsWith("core") || type.includes("crella72v1")
      );
    },
    spokenAssessmentTypes() {
      return this.assessmentTypes.filter(type => type.startsWith("spoken"));
    },
    writingAssessmentTypes() {
      return this.assessmentTypes.filter(type => type.startsWith("writing"));
    },
    assessmentGroup() {
      return {
        core: this.coreAssessmentTypes,
        spoken: this.spokenAssessmentTypes,
        writing: this.writingAssessmentTypes
      };
    },
    newRetakeConfigurations() {
      return this.retakeConfigurations.reduce<
        Record<
          string,
          {
            restrictions: Array<{
              amount: number;
              duration: number;
              time_unit: string;
            }>;
          }
        >
      >((acc, item) => {
        for (const assessmentType of this.assessmentGroup[item.key]) {
          acc[assessmentType] = {
            restrictions: item.restrictions.map(item => ({
              amount: Number(item.amount),
              duration: Number(item.duration),
              time_unit: item.time_unit
            }))
          };
        }
        return acc;
      }, {});
    }
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      for (const key of Object.keys(this.defaultRetakeConfigurations)) {
        if (key.startsWith(key)) {
          const idx = this.retakeConfigurations.findIndex(
            item => item.key === key
          );
          let defaultRestrictions = this.defaultRetakeConfigurations[key]
            .restrictions;

          this.retakeConfigurations = [
            ...this.retakeConfigurations.slice(0, idx),
            {
              ...this.retakeConfigurations[idx],
              restrictions: defaultRestrictions
            },
            ...this.retakeConfigurations.slice(idx + 1)
          ];

          this.assessmentGroup[key].forEach(assessmentType => {
            if (assessmentType in this.value) {
              const valueRestrictions = this.value[assessmentType].restrictions;
              const restrictions = defaultRestrictions.reduce<
                typeof defaultRestrictions
              >((acc, item) => {
                const idx = valueRestrictions.findIndex(
                  valueItem => valueItem.time_unit === item.time_unit
                );
                if (idx !== -1) {
                  acc.push(valueRestrictions[idx]);
                } else {
                  acc.push(item);
                }
                return acc;
              }, []);
              this.retakeConfigurations = [
                ...this.retakeConfigurations.slice(0, idx),
                {
                  ...this.retakeConfigurations[idx],
                  restrictions
                },
                ...this.retakeConfigurations.slice(idx + 1)
              ];
            }
          });
        }
      }
      this.isReady = true;
    }, 10);
  },
  watch: {
    newRetakeConfigurations: {
      handler: function(newValue) {
        if (!this.isReady) return;
        if (!this.$refs.retakeValidator) return;
        (this.$refs.retakeValidator as any).validate().then(isValid => {
          if (isValid) {
            this.$emit("input", newValue);
            this.$emit("error", false);
          } else {
            this.$emit("error", true);
          }
        });
      },
      deep: true
    }
  }
});
